<template>


    <div class="main-wrapper">

        <layout-header></layout-header>

        <loading :active="loading"
                 color="#db7015"
                 :is-full-page="true"></loading>

        <div class="content">
            <div class="container">
                <div class="row booking-view theiaStickySidebar">

                    <div class="stickyside col-lg-4">

                        <booking-sidebar
                            :availability="availability"
                            :details="details"
                            :count-days="countDays"
                            :payload="payload"
                            :rate="rate"
                        ></booking-sidebar>

                    </div>

                    <div class="col-lg-8" v-if="success && details">
                        <div class="card provider-widget clearfix">
                            <div class="card-body">

                                <div class="booking-view__success mt-4 mb-4 text-center">
                                    <h3 class="text-success"><strong>Gratulacje! </strong>Rezerwacja założona</h3>
                                    <p class="mt-3 mb-1">Twoja rezerwacja została pomyślnie założona</p>
                                    <p class="mb-5">Numer Twojej rezerwacji: <strong>{{ successData.reservation_number }}</strong></p>
                                    <svg xmlns="http://www.w3.org/2000/svg" style="width: 50%; margin: 0 auto;"
                                         class="mb-5" viewBox="0 0 117.72 117.72" fill="#01A601">
                                        <g>
                                            <path class="st0"
                                                  d="M58.86,0c9.13,0,17.77,2.08,25.49,5.79c-3.16,2.5-6.09,4.9-8.82,7.21c-5.2-1.89-10.81-2.92-16.66-2.92 c-13.47,0-25.67,5.46-34.49,14.29c-8.83,8.83-14.29,21.02-14.29,34.49c0,13.47,5.46,25.66,14.29,34.49 c8.83,8.83,21.02,14.29,34.49,14.29s25.67-5.46,34.49-14.29c8.83-8.83,14.29-21.02,14.29-34.49c0-3.2-0.31-6.34-0.9-9.37 c2.53-3.3,5.12-6.59,7.77-9.85c2.08,6.02,3.21,12.49,3.21,19.22c0,16.25-6.59,30.97-17.24,41.62 c-10.65,10.65-25.37,17.24-41.62,17.24c-16.25,0-30.97-6.59-41.62-17.24C6.59,89.83,0,75.11,0,58.86 c0-16.25,6.59-30.97,17.24-41.62S42.61,0,58.86,0L58.86,0z M31.44,49.19L45.8,49l1.07,0.28c2.9,1.67,5.63,3.58,8.18,5.74 c1.84,1.56,3.6,3.26,5.27,5.1c5.15-8.29,10.64-15.9,16.44-22.9c6.35-7.67,13.09-14.63,20.17-20.98l1.4-0.54H114l-3.16,3.51 C101.13,30,92.32,41.15,84.36,52.65C76.4,64.16,69.28,76.04,62.95,88.27l-1.97,3.8l-1.81-3.87c-3.34-7.17-7.34-13.75-12.11-19.63 c-4.77-5.88-10.32-11.1-16.79-15.54L31.44,49.19L31.44,49.19z"/>
                                        </g>
                                    </svg>
                                    <h3 class="mb-2  mt-5">Podsumowanie: </h3>
                                    <hr class="my-3">
                                    <div class="text-left w-75 mx-auto">

                                        <div class="w-100 d-flex justify-content-between mb-2"><strong>Hotel: </strong>
                                            <span>{{ details.name }}</span></div>
                                        <div class="w-100 d-flex justify-content-between mb-2"><strong>Adres: </strong>
                                            <span>{{ details.address._ }}</span></div>
                                        <div class="w-100 d-flex justify-content-between mb-2"><strong>Data
                                            przyjazdu: </strong> <span>{{ checkinDate }}</span></div>
                                        <div class="w-100 d-flex justify-content-between mb-2"><strong>Data
                                            wyjazdu: </strong> <span>{{ checkoutDate }}</span></div>
                                        <div class="w-100 d-flex justify-content-between mb-2"><strong>Długość
                                            pobytu: </strong> <span>{{ countDays }} {{
                                                (countDays > 1) ? 'dni' : 'dzień'
                                            }}</span></div>
                                        <div class="w-100 d-flex justify-content-between mb-2"><strong>Liczba
                                            osób: </strong>
                                            <span>{{ parseInt(payload.adults) + parseInt(payload.children) }} </span>
                                        </div>

                                    </div>
                                    <div class="row mt-5">
                                        <div class="col-sm-4">
<!--                                            <router-link :to="{name: '/admin/reservation/item', params: {id: successData.reservation_id}}" class="btn btn-primary submit-btn">-->
<!--                                                <i class="fas fa-edit mr-2"></i> Zarządzaj rezerwacją-->
<!--                                            </router-link>-->
                                            <a href="/user-bookings" style="font-size: 14px;" class="btn px-3 btn-primary submit-btn"><i class="fas fa-edit mr-2"></i> Zarządzaj rezerwacją</a>
                                        </div>

                                        <div class="col-sm-4" v-if="successData">
                                            <a href="#" @click.prevent="downloadVoucher(successData.voucher.id, 'pl')"  style="font-size: 14px;" class="btn  px-3 btn-success submit-btn"><i class="fas  fa-file-pdf mr-2"></i> Pobierz Voucher</a>
                                        </div>

                                        <div class="col-sm-4">
                                            <a  data-toggle="modal" style="font-size: 14px;" :disabled="cancellationInProgress" data-target="#cancelConfirmModal" class="btn btn-danger  px-3 submit-btn" type="submit"><i
                                                class="fas fa-times mr-2"></i> Anuluj rezerwację
                                            </a>
                                        </div>

                                        <div class="col-sm-12" v-if="cancellationInProgress">
                                            <p class="alert alert-success">Prośba o anulowanie rezerwacji została wysłana.</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="col-lg-8" v-else>

                        <hotel-item v-if="details" :hotel-details="details"></hotel-item>

                        <form>
                            <div class="service-fields mb-3">
                                <h3 class="heading-2">Wpisz swoje dane </h3>
                                <div class="row">
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label>Tytuł <span class="text-danger">*</span></label>
                                            <select class="form-control" name="title" v-model="data.title" id="title">
                                                <option value="MR">Pan</option>
                                                <option value="MS">Pani</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label>Imię <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" name="firstname"
                                                   v-model="data.firstname">
                                        </div>
                                    </div>
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label>Nazwisko <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" name="lastname"
                                                   v-model="data.lastname">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label>Adres <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" name="street" v-model="data.street">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Miasto <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" name="city" v-model="data.city">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Kod pocztowy <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" name="postal_code"
                                                   v-model="data.postal_code">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Państwo <span class="text-danger">*</span></label>
                                            <select class="form-control" name="country" v-model="data.country"
                                                    id="country">
                                                <option value="Poland">Polska</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Narodowość <span class="text-danger">*</span></label>
                                            <select class="form-control" name="nationality" v-model="data.nationality"
                                                    id="nationality">
                                                <option value="PL">Polska</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Adres e-mail <span class="text-danger">*</span></label>
                                            <input class="form-control" type="email" name="email" v-model="data.email">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Telefon <span class="text-danger">*</span></label>
                                            <input class="form-control" type="phone" name="phone" v-model="data.phone">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="service-fields mb-3 mt-5">
                                <h3 class="heading-2">Dane gości </h3>
                                <hr>
                                <div class="row" v-for="(guest, index) in data.room[0].persons" :key="index">
                                    <h4 class="col-lg-12 heading-2">Gość {{ index + 1 }} </h4>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label>Tytuł <span class="text-danger">*</span></label>
                                            <select class="form-control" :name="`title_guest_${index}`"
                                                    v-model="data.room[0].persons[index].title"
                                                    :id="`title_guest_${index}`">
                                                <option value="MR">Pan</option>
                                                <option value="MRS">Pani</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label>Imię <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" :name="`firstname_guest_${index}`"
                                                   v-model="data.room[0].persons[index].firstname"
                                                   :id="`firstname_guest_${index}`">
                                        </div>
                                    </div>
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label>Nazwisko <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" :name="`lastname_guest_${index}`"
                                                   v-model="data.room[0].persons[index].lastname"
                                                   :id="`lastname_guest_${index}`">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="service-fields mb-3  d-none">
                                <h3 class="heading-2 ">Preferencje pokoju</h3>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label>Przeznaczenie <span class="text-danger">*</span></label>
                                            <select class="form-control form-select" v-model="data.smoking">
                                                <option value="niepalacy">Niepalący</option>
                                                <option value="palacy">Palący</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-none" >
                                        <div class="form-group">
                                            <label>Kupon rabatowy </label>
                                            <input class="form-control" type="text" name="postal_code"
                                                   v-model="data.coupon_code">
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div class="service-fields mb-3">
                                <h3 class="heading-2">Dodatkowe informacje</h3>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label>Uwagi dla hotelu</label>
                                            <textarea class="form-control service-desc"
                                                      v-model="data.special_instructions"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="service-fields mb-3">
                                <h3 class="heading-2">Dane do faktury: </h3>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Nazwa firmy <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" name="company_name"
                                                   v-model="data.company_name">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>NIP <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" name="company_nip"
                                                   v-model="data.company_nip">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label>Adres <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" name="company_street"
                                                   v-model="data.company_street">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Miasto <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" name="company_city"
                                                   v-model="data.company_city">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Kod pocztowy <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" name="company_postal_code"
                                                   v-model="data.company_postal_code">
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label>Dodatkowe informacje</label>
                                            <input class="form-control" type="text" name="company_invoice_info"
                                                   v-model="data.company_invoice_info">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 mt-4">
                                        <div class="form-group">
                                            <label>* - Pola wymagane</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="submit-section">
                                <button class="btn btn-primary submit-btn" @click.prevent="submitReservation"
                                        type="submit"><i class="fas fa-lock mr-2"></i> Rezerwuj
                                </button>
                            </div>
                        </form>


                    </div>

                </div>

            </div>
        </div>

        <layout-footer></layout-footer>

    </div>

    <div class="modal fade" id="priceChangeModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p><strong>Cena za pokój w międzyczasie uległa zmianie. Czy chcesz zobaczyć aktualną cenę?</strong></p>
                </div>
                <div class="modal-footer">
                    <a href="#" @click.prevent="reloadPrices" class="btn btn-success si_accept_confirm mr-3">Przeładuj ceny!</a>
                    <a class="btn btn-danger si_accept_cancel" @click="moveBack">Wybierz inny pokój</a>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="priceChangeModalError" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p><strong>Nie można pobrać nowej ceny do obecnego pokoju. Wybierz inny pokój.</strong></p>
                </div>
                <div class="modal-footer">
                    <a class="btn btn-danger si_accept_cancel" @click="moveBack">Wybierz inny pokój</a>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="cancelConfirmModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p><strong>Czy na pewno chcesz anulować rezerwację?</strong></p>
                </div>
                <div class="modal-footer">	<a href="#" @click.prevent="cancelReservation" class="btn btn-success si_accept_confirm mr-3">Tak</a>
                    <button type="button" class="btn btn-danger si_accept_cancel" data-dismiss="modal">Anuluj</button>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';

import moment from 'moment';

export default {
    components: {
        Loading,
    },

    data() {
        return {
            loading: false,

            details: null,
            availability: null,

            success: false,
            successData: null,

            data: {
                title: 'MR', // ok form
                firstname: 'Jacek', // ok form
                lastname: 'Nowak', //ok form
                street: 'Krakowska', // ok form
                city: 'Kraków', //ok form
                postal_code: '31-422', // ok form
                email: 'test@test.com', // ok form
                phone: '123456789', // ok form
                country: 'Poland', // ok form
                nationality: 'PL', // ok form

                hotel_city: 'Zakopane', // ok setDataPayload()
                hotel_name: 'Apartamenty Górskie', // ok setDataPayload()
                hotel_address1: 'Krupówki', // ok setDataPayload()
                hotel_address2: '31a/25', // ok setDataPayload()
                hotel_phone: '987654321', // ok setDataPayload()
                hotel_image: '',
                check_in: '2023-11-26', // ok setDataPayload()
                check_out: '2023-11-28', // ok setDataPayload()

                reservation_number: '',  // ok empty
                system_id: 6, // ok empty
                invoice_type: 1, // ok empty
                invoice_send_type: 1, // ok empty

                company_name: 'Travelpartner', // ok form
                company_street: 'Wadowicka 8a', // ok form
                company_city: 'Kraków', // ok form
                company_postal_code: '30-415', // ok form
                company_nip: '3216574841', // ok form
                company_invoice_info: '', // ok form
                booking_reservation_id: '0',
                booking_reservation_status: 'P',

                room_name: '', // ok setDataPayload()
                mealplan: 'Room only', // ok setDataPayload()
                room_count: '1',
                bedsPerRoom: '1',
                refundable_until: '2023-11-25 10:00', // ok setDataPayload()

                smoking: 'niepalacy', // ok form
                special_instructions: '', // ok form
                room_persons: 1, //ok form
                room: [
                    {
                        persons: [
                            {
                                title: 'MR',
                                firstname: 'Jacek',
                                lastname: 'Nowak',
                            },
                        ],
                    },
                ], // OK ?

                _price_org: '78.64',
                _price_org_total: '78.64',
                _price_org_currency: 'EUR',
                _price_netto: '337',
                _price_wo_discount: '436',
                price: '422',
                coupon_code: '', // ok form
                payment_type: 'transfer',
            },

            rate: 4.60,

            pricesReloaded: false,

            payload: {
                hotelId: null,
                roomId: null,
                boardTypeId: null,
                roomTypeId: null,
                checkin: null,
                checkout: null,
                adults: null,
                children: null,
                ageOne: 0,
                ageTwo: 0,
                ageThree: 0,
            },

            reservationToCancel: null,
            cancellationInProgress: false,
        }
    },

    computed: {
        countDays() {
            if (this.payload.checkin && this.payload.checkout) {
                const checkin = new Date(this.payload.checkin);
                const checkout = new Date(this.payload.checkout);

                const oneDay = 24 * 60 * 60 * 1000;
                const diffDays = Math.abs((checkout.getTime() - checkin.getTime()) / (oneDay));
                return diffDays;
            }
            return null;
        },

        checkinDate() {
            if (this.payload.checkin) {
                let date = new Date(this.payload.checkin);
                var options = {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric"
                };

                return date.toLocaleDateString("pl", options)

            }
            return null;
        },

        checkoutDate() {
            if (this.payload.checkout) {
                let date = new Date(this.payload.checkout);
                var options = {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric"
                };

                return date.toLocaleDateString("pl", options)

            }
            return null;
        },
    },

    methods: {
        checkAvailability() {
            this.loading = true;
            axios.post('/api/book/check-availability', this.payload)
                .then(res => {
                    this.availability = res.data;
                    this.getHotelDetails();
                })
                .catch(error => {
                    if(!this.pricesReloaded) {
                        $('#priceChangeModal').modal('show');
                    } else {
                        $('#priceChangeModalError').modal('show');
                    }

                    console.log(error);
                })
        },

        reloadPrices() {
            this.loading = true;
            this.$emit('loading', true);

            axios.post('/api/search/hotel-by-id', this.payload)
                .then(res => {
                    console.log('hotelById', res.data);
                    $('#priceChangeModal').modal('hide');
                    this.details = res.data.details;
                    this.pricesReloaded = true;
                    this.checkAvailability();
                })
                .catch(error => {
                    this.error = 'Wybrany hotel jest niedostępny w tych dniach';
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('loading', false);
                });
        },

        moveBack() {
            $('#priceChangeModal').modal('hide');
            $('#priceChangeModalError').modal('hide');

            // Set a flag in localStorage
            localStorage.setItem('shouldReloadPrices', 'true');

            this.$router.go(-1);
        },

        cancel(id) {
            this.reservationToCancel = id;
        },

        downloadVoucher(voucherId, language) {
            this.loading = true;

            // Call the backend to generate and stream the PDF
            axios({
                method: 'get',
                url: `/api/voucher/${voucherId}/pdf?language=${language}`, // Pass the language as a query param
                responseType: 'blob', // Ensure we handle binary data
            })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Voucher-${voucherId}-${language}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(error => {
                    console.error('Error downloading voucher PDF:', error.response?.data);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        cancelReservation() {
            this.loading = true;
            axios.post('/api/reservation/cancel', {reservation: this.reservationToCancel})
                .then(res => {
                    console.log(res);
                    this.success = res.data;
                    $('#cancelConfirmModal').modal('hide');
                    this.reservationToCancel = null;
                    this.cancellationInProgress = true;
                })
                .catch(err => {
                    console.log(err);
                }).finally(() => {
                this.loading = false;
            })
        },

        submitReservation() {
            this.loading = true;
            axios.post(`/api/book/hotel`, this.data)
                .then(res => {
                    this.successData = res.data.data;
                    this.success = true;
                    this.reservationToCancel = this.successData.reservation_id;
                    console.log(this.reservationToCancel);
                    window.scrollTo(0,0);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        setDataPayload() {
            this.data.room = [
                {persons: []}
            ];
            for (let i = 0; i < this.payload.adults; i++) {
                this.data.room[0].persons.push({title: '', firstname: '', lastname: ''});
            }

            this.data.hotelId = this.payload.hotelId;
            this.data.roomId = this.payload.roomId;
            this.data.roomTypeId = this.payload.roomTypeId;
            this.data.boardTypeId = this.payload.boardTypeId;
            this.data.check_in = this.payload.checkin;
            this.data.check_out = this.payload.checkout;
            this.data.bedsPerRoom = parseInt(this.payload.adults) + parseInt(this.payload.children);
            this.data.hotel_city = this.details.address.city;
            this.data.hotel_name = this.details.name;
            this.data.hotel_address1 = this.details.address.street1 + ' ' + this.details.address.street2;
            this.data.hotel_address2 = this.details.address.zip + ' ' + this.details.address.city;
            this.data.hotel_image = (this.details.image[0]) ? this.details.image[0].url : this.details.image.url
            this.data.hotel_phone = this.details.phone;
            this.data.room_persons = parseInt(this.payload.adults) + parseInt(this.payload.children);
            this.data.room_name = this.availability.hotel.channel.room.roomType.name;
            this.data.mealplan = this.availability.hotel.channel.room.boardType.name;

            this.data._price_org = this.availability.hotel.channel.room.price;
            this.data._price_org_total = (this.availability.hotel.channel.room.price * this.countDays).toFixed(2);
            this.data._price_org_currency = 'EUR';
            this.data._price_netto = ((this.availability.hotel.channel.room.price  * this.countDays * 1.10 * this.rate) / 1.23).toFixed(2);
            this.data._price_wo_discount = (this.availability.hotel.channel.room.price * this.countDays * 1.10 * this.rate).toFixed(2);
            this.data.price = (this.availability.hotel.channel.room.price * this.countDays * 1.10 * this.rate).toFixed(2);

            const refundable = new Date(this.availability.hotel.channel.room.bookingTerms.cancellationPolicy.cxlDate);
            this.data.refundable_until = moment(refundable).format('YYYY-MM-DD HH:mm');
        },

        getHotelDetails() {
            this.loading = true;
            axios.post(`/api/hotel/details/${this.payload.hotelId}`)
                .then(res => {
                    this.details = res.data.hotel;
                    this.setDataPayload();
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getEurPlnRate() {
            this.loading = true;
            this.$emit('loading', true);
            axios.get('/api/settings/eur-pln-rate')
                .then(res => {
                    this.rate = res.data.rate;
                    this.effectiveDate = res.data.effectiveDate;
                    console.log(this.rate);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('loading', false);
                });
        },
    },

    mounted() {
        if (this.globalUser) {
            this.data.title = this.globalUser.title;
            this.data.firstname = this.globalUser.firstname;
            this.data.lastname = this.globalUser.lastname;
            this.data.street = this.globalUser.street;
            this.data.city = this.globalUser.city;
            this.data.postal_code = this.globalUser.postal_code;
            this.data.email = this.globalUser.email;
            this.data.phone = this.globalUser.phone;
            this.data.country = this.globalUser.country;

            this.data.company_name = this.globalUser.company_name;
            this.data.company_street = this.globalUser.company_street;
            this.data.company_city = this.globalUser.company_city;
            this.data.company_postal_code = this.globalUser.company_postal_code;
            this.data.company_nip = this.globalUser.company_vat;
        }
        this.getEurPlnRate();
        if (this.$route.params.checkin && this.$route.params.checkout) {
            this.payload.hotelId = this.$route.params.hotelId;
            this.payload.roomId = this.$route.params.roomId;
            this.payload.boardTypeId = this.$route.params.boardTypeId;
            this.payload.roomTypeId = this.$route.params.roomTypeId;
            this.payload.checkin = this.$route.params.checkin;
            this.payload.checkout = this.$route.params.checkout;
            this.payload.adults = this.$route.params.adults;
            this.payload.children = this.$route.params.children;
            this.payload.ageOne = this.$route.params.ageOne;
            this.payload.ageTwo = this.$route.params.ageTwo;
            this.payload.ageThree = this.$route.params.ageThree;
        }

        this.checkAvailability();
    },
}
</script>
